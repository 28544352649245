var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav-bar'),_c('div',{staticClass:"gaoxiao"},[_c('div',{staticClass:"banxin"},[_vm._m(0),_c('div',{staticClass:"gaobai"},[_c('div',{staticClass:"gaotop"},[_c('div',{staticClass:"gaoli"},[_c('div',{staticClass:"gaoline"}),_c('div',{staticClass:"zhantitle"},[_vm._v(" "+_vm._s(_vm.need.title)+" ")])]),_vm._m(1)]),_c('div',{staticClass:"liubox"},[_c('div',{staticClass:"liuli"},[_vm._m(2),_c('div',{staticClass:"liurigth"},[_c('img',{staticClass:"numpic",attrs:{"src":require("../../../assets/img/images/qiuh_03.png"),"alt":""}}),_c('div',{staticClass:"liurtxt"},[_vm._v(" 需求进展 ")]),_c('div',{staticClass:"liuzhi"},[_c('div',{staticClass:"liuda"},[_c('img',{staticClass:"lvjian",attrs:{"src":require("../../../assets/img/images/lvjian_07.png"),"alt":""}}),_vm._m(3),_c('div',{staticClass:"dtitle oneline"},[_vm._v(" "+_vm._s(_vm.need.company_name)+" ")]),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"liuda"},[_vm._m(6),_c('div',{staticClass:"dtitle oneline"},[_vm._v(" "+_vm._s(_vm.need.created_at)+" ")]),_c('div',{staticClass:"onedis"},[_c('div',{staticClass:"onetxt"},[_vm._v(" "+_vm._s(_vm.need.title)+" ")])]),_c('div',{staticClass:"onedis"},[_c('div',{staticClass:"onetxt"},[_vm._v(" 审核状态： "+_vm._s(["", "通过", "拒绝", "待审核"][_vm.need.is_grant])+" ")])])])])])]),_c('div',{staticClass:"liuli"},[_vm._m(7),_c('div',{staticClass:"liurigth"},[_c('img',{staticClass:"numpic",attrs:{"src":require("../../../assets/img/images/qiui_03.png"),"alt":""}}),_c('div',{staticClass:"liurtxt"},[_vm._v(" 筛选专家 ")]),_c('div',{staticClass:"liuzhi"},[_c('div',{staticClass:"liuda"},[_vm._m(8),_c('div',{staticClass:"dtitle oneline"},[_vm._v(" "+_vm._s(_vm.need.updated_at)+" ")]),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)])])])]),_vm._m(13),_vm._m(14)])])])]),_c('tab-bar')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qiyetop"},[_c('img',{staticClass:"zhengpic",attrs:{"src":require("../../../assets/img/images/tl_06.png"),"alt":""}}),_c('div',{staticClass:"zhengtitle"},[_vm._v("需求进展")]),_c('img',{staticClass:"zhengpic",attrs:{"src":require("../../../assets/img/images/tr_07.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"youke"},[_c('div',{staticClass:"youkeli"},[_c('img',{staticClass:"kepic",attrs:{"src":require("../../../assets/img/images/qiue_07.png"),"alt":""}}),_c('div',{staticClass:"ketxt"},[_vm._v(" 2 ")])]),_c('div',{staticClass:"youkeli"},[_c('img',{staticClass:"kepic",attrs:{"src":require("../../../assets/img/images/qiuf_07.png"),"alt":""}}),_c('div',{staticClass:"ketxt oneline"},[_vm._v(" 项目动态 ")])]),_c('div',{staticClass:"youkeli"},[_c('img',{staticClass:"kepic",attrs:{"src":require("../../../assets/img/images/qiug_07.png"),"alt":""}}),_c('div',{staticClass:"ketxt oneline"},[_vm._v(" 在线客服 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liuleft"},[_c('img',{staticClass:"liupic",attrs:{"src":require("../../../assets/img/images/qiua_03.png"),"alt":""}}),_c('div',{staticClass:"liuline"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dcen"},[_c('img',{staticClass:"dpic",attrs:{"src":require("../../../assets/img/images/na_03.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onedis"},[_c('img',{staticClass:"onepic",attrs:{"src":require("../../../assets/img/images/onea_03.png"),"alt":""}}),_c('div',{staticClass:"onetxt oneline"},[_vm._v(" 未填写 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onedis"},[_c('img',{staticClass:"onepic",attrs:{"src":require("../../../assets/img/images/oneb_03.png"),"alt":""}}),_c('div',{staticClass:"onetxt oneline"},[_vm._v(" 未填写 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dcen"},[_c('img',{staticClass:"dpic",attrs:{"src":require("../../../assets/img/images/nb_06.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liuleft"},[_c('img',{staticClass:"liupic",attrs:{"src":require("../../../assets/img/images/qiub_03.png"),"alt":""}}),_c('div',{staticClass:"liuline"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dcen"},[_c('img',{staticClass:"dpic",attrs:{"src":require("../../../assets/img/images/nc_07.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onedis"},[_c('img',{staticClass:"onepic",attrs:{"src":require("../../../assets/img/images/onea_03.png"),"alt":""}}),_c('div',{staticClass:"onetxt oneline"},[_vm._v(" 系统第1次生成匹配报告 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onedis"},[_c('img',{staticClass:"onepic",attrs:{"src":require("../../../assets/img/images/oneb_03.png"),"alt":""}}),_c('div',{staticClass:"onetxt oneline"},[_vm._v(" 专家：2人 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onedis"},[_c('img',{staticClass:"onepic",attrs:{"src":require("../../../assets/img/images/oneb_03.png"),"alt":""}}),_c('div',{staticClass:"onetxt oneline"},[_vm._v(" 成果：2项 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onedis"},[_c('img',{staticClass:"onepic",attrs:{"src":require("../../../assets/img/images/oneb_03.png"),"alt":""}}),_c('div',{staticClass:"onetxt oneline"},[_vm._v(" 科研团队：0个 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liuli"},[_c('div',{staticClass:"liuleft"},[_c('img',{staticClass:"liupic",attrs:{"src":require("../../../assets/img/images/qiuc_03.png"),"alt":""}}),_c('div',{staticClass:"liuline"})]),_c('div',{staticClass:"liurigth"},[_c('img',{staticClass:"numpic",attrs:{"src":require("../../../assets/img/images/qiuj_03.png"),"alt":""}}),_c('div',{staticClass:"liurtxt"},[_vm._v(" 筛选专家 ")]),_c('div',{staticClass:"liuzhi"},[_c('div',{staticClass:"liuda"},[_c('div',{staticClass:"dcen"},[_c('img',{staticClass:"dpic",attrs:{"src":require("../../../assets/img/images/nd_07.png"),"alt":""}})]),_c('div',{staticClass:"dtitle oneline"},[_vm._v(" 2021.5.17 ")]),_c('div',{staticClass:"onedis"},[_c('div',{staticClass:"onetxt oneline"},[_vm._v(" 关于专家的XXX的跟踪记录 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liuli"},[_c('div',{staticClass:"liuleft"},[_c('img',{staticClass:"liupic",attrs:{"src":require("../../../assets/img/images/qiud_03.png"),"alt":""}})]),_c('div',{staticClass:"liurigth"},[_c('img',{staticClass:"numpic",attrs:{"src":require("../../../assets/img/images/qiuk_03.png"),"alt":""}}),_c('div',{staticClass:"liurtxt"},[_vm._v(" 服务结果 ")]),_c('div',{staticClass:"liuzhi"},[_c('div',{staticClass:"liuda"},[_c('div',{staticClass:"dcen"},[_c('img',{staticClass:"dpic",attrs:{"src":require("../../../assets/img/images/nd_07.png"),"alt":""}})]),_c('div',{staticClass:"dtitle oneline"},[_vm._v(" 2021.5.17 ")]),_c('div',{staticClass:"onedis"},[_c('div',{staticClass:"onetxt oneline"},[_vm._v(" 对接成功 ")]),_c('div',{staticClass:"onetxt oneline"},[_vm._v(" 对接专家：XXX ")])])])])])])
}]

export { render, staticRenderFns }