import { render, staticRenderFns } from "./NeedCgal.vue?vue&type=template&id=147bc41e&scoped=true&"
import script from "./NeedCgal.vue?vue&type=script&lang=js&"
export * from "./NeedCgal.vue?vue&type=script&lang=js&"
import style0 from "./NeedCgal.vue?vue&type=style&index=0&id=147bc41e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147bc41e",
  null
  
)

export default component.exports