<template>
	<div>
		<nav-bar></nav-bar>
		<!-- 需求进展 -->
		<div class="gaoxiao">
			<div class="banxin">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">需求进展</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="gaobai">
					<div class="gaotop">
						<div class="gaoli">
							<div class="gaoline"></div>
							<div class="zhantitle">
								{{need.title}}
							</div>
						</div>
						<div class="youke">
							<div class="youkeli">
								<img src="../../../assets/img/images/qiue_07.png" class="kepic" alt="" />
								<div class="ketxt">
									2
								</div>
							</div>
							<div class="youkeli">
								<img src="../../../assets/img/images/qiuf_07.png" class="kepic" alt="" />
								<div class="ketxt oneline">
									项目动态
								</div>
							</div>
							<div class="youkeli">
								<img src="../../../assets/img/images/qiug_07.png" class="kepic" alt="" />
								<div class="ketxt oneline">
									在线客服
								</div>
							</div>
						</div>
					</div>
					
					<div class="liubox">
						<div class="liuli">
							<div class="liuleft">
								<img src="../../../assets/img/images/qiua_03.png" class="liupic" alt="" />
								<div class="liuline"></div>
							</div>
							<div class="liurigth">
								<img src="../../../assets/img/images/qiuh_03.png" class="numpic" alt="" />
								<div class="liurtxt">
									需求进展
								</div>
								<div class="liuzhi">
									<div class="liuda">
										<img src="../../../assets/img/images/lvjian_07.png" class="lvjian" alt="" />
										<div class="dcen">
											<img src="../../../assets/img/images/na_03.png" class="dpic" alt="" />
										</div>
										<div class="dtitle oneline">
											{{ need.company_name }}
										</div>
										<div class="onedis">
											<img src="../../../assets/img/images/onea_03.png" class="onepic" alt="" />
											<div class="onetxt oneline">
												未填写
											</div>
										</div>
										<div class="onedis">
											<img src="../../../assets/img/images/oneb_03.png" class="onepic" alt="" />
											<div class="onetxt oneline">
												未填写
											</div>
										</div>
									</div>
									
									<div class="liuda">
										<div class="dcen">
											<img src="../../../assets/img/images/nb_06.png" class="dpic" alt="" />
										</div>
										<div class="dtitle oneline">
											{{ need.created_at }}
										</div>
										<div class="onedis">
											<div class="onetxt">
												{{ need.title }}
											</div>
										</div>
										<div class="onedis">
											<div class="onetxt">
												审核状态： {{ ["", "通过", "拒绝", "待审核"][need.is_grant] }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="liuli">
							<div class="liuleft">
								<img src="../../../assets/img/images/qiub_03.png" class="liupic" alt="" />
								<div class="liuline"></div>
							</div>
							<div class="liurigth">
								<img src="../../../assets/img/images/qiui_03.png" class="numpic" alt="" />
								<div class="liurtxt">
									筛选专家
								</div>
								<div class="liuzhi">
									<div class="liuda">
										<div class="dcen">
											<img src="../../../assets/img/images/nc_07.png" class="dpic" alt="" />
										</div>
										<div class="dtitle oneline">
											{{ need.updated_at }}
										</div>
										<div class="onedis">
											<img src="../../../assets/img/images/onea_03.png" class="onepic" alt="" />
											<div class="onetxt oneline">
												系统第1次生成匹配报告
											</div>
										</div>
										<div class="onedis">
											<img src="../../../assets/img/images/oneb_03.png" class="onepic" alt="" />
											<div class="onetxt oneline">
												专家：2人
											</div>
										</div>
										<div class="onedis">
											<img src="../../../assets/img/images/oneb_03.png" class="onepic" alt="" />
											<div class="onetxt oneline">
												成果：2项
											</div>
										</div>
										<div class="onedis">
											<img src="../../../assets/img/images/oneb_03.png" class="onepic" alt="" />
											<div class="onetxt oneline">
												科研团队：0个
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="liuli">
							<div class="liuleft">
								<img src="../../../assets/img/images/qiuc_03.png" class="liupic" alt="" />
								<div class="liuline"></div>
							</div>
							<div class="liurigth">
								<img src="../../../assets/img/images/qiuj_03.png" class="numpic" alt="" />
								<div class="liurtxt">
									筛选专家
								</div>
								<div class="liuzhi">
									<div class="liuda">
										<div class="dcen">
											<img src="../../../assets/img/images/nd_07.png" class="dpic" alt="" />
										</div>
										<div class="dtitle oneline">
											2021.5.17
										</div>
										<div class="onedis">
											<div class="onetxt oneline" >
												关于专家的XXX的跟踪记录
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="liuli">
							<div class="liuleft">
								<img src="../../../assets/img/images/qiud_03.png" class="liupic" alt="" />
							</div>
							<div class="liurigth">
								<img src="../../../assets/img/images/qiuk_03.png" class="numpic" alt="" />
								<div class="liurtxt">
									服务结果
								</div>
								<div class="liuzhi">
									<div class="liuda">
										<div class="dcen">
											<img src="../../../assets/img/images/nd_07.png" class="dpic" alt="" />
										</div>
										<div class="dtitle oneline">
											2021.5.17
										</div>
										<div class="onedis">
											<div class="onetxt oneline" >
												对接成功
											</div>
											<div class="onetxt oneline" >
												对接专家：XXX
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<tab-bar></tab-bar>
	</div>
</template>

<script>
	import $ from "jquery";
	import FromList from "../../../common/input";
	import TabBar from "../../../components/common/TabBar";
	import NavBar from '../../../components/common/NavBar';
	import {
		Wisdom,
		achievementsLists,
		Data
	} from "../../../common/js/api";
	import {
		zhi
	} from "../../common/js/api";
	import {
		formatDate
	} from "../../../common/js/times"; //时间
	import { NeedXq } from "../../../common/js/api";
	export default {
		name: "zhichanyunForm",
		components: {
			NavBar,
			FromList,
			TabBar,
			NumberRoller: () => import("@/common/number-roller.vue"),
		},
		data() {
			return {
				id: "",
				 need: {},
				
			};
		},
		created() {
		    this.id = this.$route.query.id;
		    this.getXq();
		  },
		mounted() {
			
		},
		methods: {
			// 需求详情
			    getXq() {
			      NeedXq({
			        id: this.id,
			      })
			        .then((res) => {
			          // console.log(res);
			          this.need = res.data.data;
			        })
			        .catch(() => {});
			    },
		}
	};
</script>

<style scoped lang="less">
	html{
		background-color: #f6f6f6 !important;
	}
	.banxin {
		width: 1400px;
		margin: 0 auto;
	}
	.pt60 {
		padding-top: 60px;
	}

	.qiyetop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 40px;

		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}

		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}

		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}

	
	.gaoxiao{
		margin-top: 136px;
		padding:  60px 0 80px;
		background-color: #f6f6f6;
		.gaobai{
			background-color: #ffffff;
			border-radius: 3px;
			padding-bottom: 50px;
			box-shadow: 0 0 5px #dddddd;
			.gaotop{
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				padding-bottom: 40px;
				.gaoli{
					padding-top: 25px;
					display: flex;
					align-items: center;
					.gaoline{
						width: 5px;
						height: 30px;
						background-color: #0289f4;
					}
					.zhantitle{
						font-size: 30px;
						color: #0289f4;
						padding-left: 15px;
						font-weight: bold;
					}
				}
				.youke{
					display: flex;
					align-items: center;
					padding-top: 15px;
					.youkeli{
						display: flex;
						align-items: center;
						padding: 0 20px;
						border-right: 1px solid #eeeeee;
						.kepic{
							width: 18px;
							height: 18px;
							flex-shrink: 0;
						}
						.ketxt{
							font-size: 18px;
							color: #2c2c2c;
							padding-left: 8px;
						}
					}
				}
			}
			.liubox{
				padding: 0 30px;
				.liuli{
					display: flex;
					align-items: inherit;
					.liuleft{
						width: 80px;
						flex-shrink: 0;
						text-align: center;
						.liupic{
							width: 80px;
							height: 80px;
						}
						.liuline{
							width: 7px;
							background-color: #e5e5e5;
							height: calc(100% - 80px);
							margin: 0 auto;
						}
					}
					.liurigth{
						flex-grow: 1;
						padding: 0 14px;
						.numpic{
							width: 88px;
							height: 44px;
						}
						.liurtxt{
							font-size: 24px;
							color: #222222;
							padding-top: 6px;
							padding-bottom: 35px;
						}
						.liuzhi{
							display: flex;
							align-items: flex-start;
							padding-bottom: 95px;
							.liuda{
								padding: 30px 0;
								width: 285px;
								background-color: #f7fbff;
								border-radius: 5px;
								position: relative;
								box-shadow: 2px 0 5px #eeeeee;
								margin-right: 30px;
								.lvjian{
									z-index: 99;
									width: 58px;
									height: 46px;
									position: absolute;
									top: 50%;
									margin-top: -29px;
									right: -50px;
								}
								.dcen{
									text-align: center;
								}
								.dpic{
									width: 47px;
									height: 47px;
									margin: 0 auto;
								}
								.dtitle{
									padding: 27px 30px 25px;
									font-size: 18px;
									color: #222222;
									font-weight: bold;
								}
								.onedis{
									padding: 0 30px;
									margin-bottom: 12px;
									height: 25px;
									display: flex;
									align-items: center;
									.onepic{
										width: 20px;
										height: 20px;
										margin-right: 10px;
									}
									.onetxt{
										font-size: 18px;
										color: #222222;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.banxin{
			width: 98%;
			margin: 0 auto;
			display: block;
			overflow: hidden;
		}
		.gaoxiao{
			margin-top: 15rem;
		}
		.qiyetop .zhengtitle{
			font-size: 3rem;
		}
		.gaoxiao .gaobai .gaotop .gaoli .zhantitle{
			font-size: 1.8rem;
		}
		.gaoxiao .gaobai .gaotop .gaoli .gaoline{
			height: 2rem;
		}
		.gaoxiao .gaobai .gaotop .youke .youkeli .kepic{
			width: 1.4rem;
			height: 1.4rem;
		}
		.gaoxiao .gaobai .gaotop .youke .youkeli .ketxt{
			font-size: 1.4rem;
		}
		.gaoxiao .gaobai .gaotop .youke{
			padding-top: 1.5rem;
		}
		.gaoxiao .gaobai .liubox .liuli .liurigth .liuzhi .liuda .dtitle{
			font-size: 1.7rem;
		}
		.gaoxiao .gaobai .liubox .liuli .liurigth .liuzhi .liuda{
			width: 18rem;
		}
		.gaoxiao .gaobai .liubox .liuli .liurigth .liuzhi .liuda .onedis .onepic{
			width: 1.6rem;
			height: 1.6rem;
		}
		.gaoxiao .gaobai .liubox .liuli .liurigth .liuzhi .liuda .onedis{
			padding: 0.5rem 1.5rem;
			height: auto;
		}
		.gaoxiao .gaobai .liubox .liuli .liurigth .liuzhi .liuda .onedis .onetxt{
			font-size: 1.6rem;
		}
	}
</style>