<template>
    <div>

        <con-tab ></con-tab>
        <need-nei class="nei" ></need-nei>
    </div>
</template>

<script>
	import ConTab from "../../components/common/ConTab";
    import NavBar from "../../components/common/faNavBar";
    import NeedNei from "./base/NeedCgal";
    export default {
        name: "Needcgal",
        components:{
            NavBar,
            NeedNei,
			ConTab
        }
    }
</script>

<style scoped lang="less">
    .nei{
        margin-top: 6px;
    }
</style>